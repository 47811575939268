import React, { useCallback, useState } from 'react';
import styles from './FileUploadArea.module.scss';
import { DownloadIcon } from '../../components/icon/DownloadIcon';
import { CloseIcon } from '../../components/icon/CloseIcon';

export function FileUploadArea(props: Props) {
  const { title, setFiles } = props;

  const [files, setAreaFiles] = useState<File[]>([]);

  const filesDisabled = files.length === 0;
  const filesEnabled = !filesDisabled;

  const onChangeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      e.target.style.color = '#000111';
      const fileArray = Array.from(selectedFiles);
      setFiles(fileArray);
      setAreaFiles(fileArray);
    } else {
      e.target.style.color = 'gray';
      setFiles([]);
      setAreaFiles([]);
    }
  }, []);

  const onClearFile = () => {
    const inputValue = document.getElementById(
      'file-input',
    ) as HTMLInputElement;
    if (inputValue) {
      inputValue.style.color = 'gray';
      inputValue.value = '';
      setFiles([]);
      setAreaFiles([]);
    }
  };

  const onuploadFile = () => {
    const inputValue = document.getElementById(
      'file-input',
    ) as HTMLInputElement;
    if (inputValue) {
      inputValue.click();
    }
  };

  return (
    <>
      <div className={styles.uploadArea}>
        <div className={styles.iconArea}>
          <button
            id="fileSelect"
            type="button"
            onClick={onuploadFile}
            className={styles.iconButtonArea}
          >
            <span className={styles.uploadIcon}>
              <DownloadIcon />
            </span>
            <span className={styles.uploadTitle}>{title}</span>
          </button>
        </div>
        <div className={styles.inputArea}>
          <input
            type="file"
            accept=".pdf,.jpg,.jpeg,.png"
            multiple={true}
            onChange={onChangeFile}
            className={styles.fileInput}
            id="file-input"
          />
          {filesEnabled && (
            <button
              id="fileClear"
              type="button"
              onClick={onClearFile}
              disabled={filesDisabled}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

type Props = {
  title: string;
  setFiles: (value: File[]) => void;
};
