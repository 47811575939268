/* eslint-disable no-useless-catch */
import React, {
  MouseEvent,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import styles from './ChatGptDialog.module.scss';
import { PrimaryButton } from '../../components/button/PrimaryButton';
import { ChatGptTextarea } from './ChatGptTextarea';
import { ChatGptApi } from '../../../../state/api/chatgpt/ChatGptApi';
import { CarePlanTableTwo } from '../../../../domain/careplan/tabletwo/CarePlanTableTwo';
import { useSelector } from 'react-redux';
import { State } from '../../../../state/store';
import { Loading } from '../../components/loading/Loading';
import { ChatGptNeed } from '../../../../domain/careplan/tabletwo/need/ChatGptTableTwo';
import { CarePlanTableTwoApi } from '../../../../state/api/careplan/tabletwo/CarePlanTableTwoApi';
import { EditedCaraPlanTableTwo } from '../../../../state/api/careplan/tabletwo/EditedCaraPlanTableTwo';
import { RegisterTableTwo } from '../../../../state/api/careplan/tabletwo/RegisterTableTwo';
import { Need } from '../../../../domain/careplan/tabletwo/need/Need';
import { LongTermGoal } from '../../../../domain/careplan/tabletwo/longtermgoal/LongTermGoal';
import { ShortTermGoal } from '../../../../domain/careplan/tabletwo/shorttermgoal/ShortTermGoal';
import { SupportService } from '../../../../domain/careplan/tabletwo/supportservice/SupportService';
import { SupportServiceType } from '../../../../domain/careplan/tabletwo/supportservicetype/SupportServiceType';
import { Create } from '../../../../state/api/chatgpt/Create';
import { ChatgptManageUsage } from '../../../../domain/chatgpt/ChatgptManageUsage';
import OutsideClickHandler from 'react-outside-click-handler';
import { DownArrowIcon } from '../../components/icon/DownArrowIcon';
import { PromptHistory } from '../../../../domain/chatgpt/PromptHistory';
import { ChatgptUsageCount } from '../../../../domain/chatgpt/ChatgptUsageCount';
import { FileUploadArea } from './FileUploadArea';
import { SimpleCareRecipient } from '../../../../domain/carerecipient/SimpleCareRecipient';
import { SecondaryButton } from '../../components/button/SecondaryButton';
import { AssessmentSheet } from '../../../../domain/assessmentsheet/AssessmentSheet';
import { useReceiveDataByPostMessage } from './hooks/useReceiveDataByPostMessage';
import { CloseIconChatGPT } from './icons/CloseIconChatGPT';

const placeholderUserConsultation =
  '記入例：何度か転倒してから、一人で湯船に入るのが怖くなった、お風呂は安心して入りたい。\n具合が良くなれば公民館の麻雀クラブに参加したいが、まだ自信がない。';
const placeholderFamilyConsultation =
  '記入例：（娘）本人が思いを言葉にすることが下手で、喧嘩になることが多い。左側が見えないので、外を一人で移動しないように伝えているが、運動をほとんどしないので、糖尿病の進行が不安。\n気力と共に全身の筋力が低下しているように感じる。';
const placeholderContent =
  '記入例：転倒リスクが高い（浴槽手すりなし、限界手すりなし、軽度の左片麻痺、左同名半盲）\n・入浴頻度の減少（転倒への不安、浴室環境が整っていない）\n・日中の発動量や外周、他者交流の機会減少（体力や筋力の低下、自身の低下、体調の不安）';

export enum AISendWarningLevel {
  NORMAL, // 0:通常 : 79% 以下
  CAUTION, // 1:注意 : 80% 超えたら
  WARNING, // 2:警告 : 90% 超えたら
  ERROR, // 3:エラー: 100%以上
}

export function ChatGptDialog(props: Props) {
  const {
    projectId,
    carePlanTableTwo,
    setCarePlanTableTwo,
    prevNeeds,
    setPrevNeeds,
    onRequestCloseDialog,
    usageInfo,
    careRecipient,
  } = props;

  const { summary, setSummary, milmoRecorderOrigin } =
    useReceiveDataByPostMessage();

  const [prompt, setPrompt] = useState('');
  const [userConsultation, setUserConsultation] = useState('');
  const [familyConsultation, setFamilyConsultation] = useState('');
  const [fileDataStrings, setFileDataStrings] = useState<string[]>([]);

  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [count, setCount] = useState<number | undefined>();
  const [files, setFiles] = useState<File[]>([]);

  const [limitCount, setLimitCount] = useState<number | undefined>();

  const [warningLevel, setWarningLevel] = useState<AISendWarningLevel>(
    AISendWarningLevel.NORMAL,
  );

  const [select, setSelect] = useState<'summary' | 'fileAndText'>(
    'fileAndText',
  );

  // 呼び出しメニュー用
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  // ファイル添付の有効/無効
  const fileDisabled =
    files.length === 0 || warningLevel >= AISendWarningLevel.ERROR;

  // 記述内容の有効/無効
  const descriptionEnable =
    prompt.length !== 0 &&
    userConsultation.length !== 0 &&
    warningLevel < AISendWarningLevel.ERROR;
  // AIへ送るボタンの無効 (記述内容とファイル添付がどちらも無効の時だけ無効)
  const disabled = !descriptionEnable && fileDisabled && summary.length < 1;

  // レコーダーからの連携文章と、ファイル/記述内容のいずれも入力されている
  const bothAreEntered =
    summary.length > 0 && (!fileDisabled || descriptionEnable);

  const { assessmentSheet } = useSelector(
    (state: State) => state.assessmentSheet.slots[projectId],
  ) ?? { assessmentSheet: null, latestChangeLog: null };

  const onClickContainer = useCallback(
    (event: MouseEvent<any>) => event.stopPropagation(),
    [],
  );

  const setSumCount = (): void => {
    ChatGptApi.getUsageCount().then((res) => {
      if (res instanceof ChatgptUsageCount) {
        setCount(res.sendCount);
      } else {
        setCount(0);
      }
    });
  };

  useEffect(() => {
    // 送信済み文字数取得
    setSumCount();
    if (usageInfo != null) {
      setLimitCount(usageInfo.sendLimit);
    }
  }, []);

  useEffect(() => {
    if (usageInfo != null && count != undefined) {
      setWarningLevel(getWarningLevel(getTextLimitRatio(usageInfo, count)));
    }
  }, [count]);

  // ファイル変更した時に Base64にエンコード
  useEffect(() => {
    if (files.length > 0) {
      Promise.all(files.map((file) => blobToText(file)))
        .then((texts) => {
          setFileDataStrings(texts);
        })
        .catch((error) => {
          console.error(error);
          setFileDataStrings([]);
        });
    } else {
      setFileDataStrings([]);
    }
  }, [files]);

  const isEmptyNeed = useCallback((need: Need): Boolean => {
    if (
      need.text === null &&
      need.subItems.items.every((item) => isEmptyLongTermGorl(item))
    ) {
      return true;
    }
    return false;
  }, []);

  const isEmptyLongTermGorl = useCallback(
    (longTermGoal: LongTermGoal): Boolean => {
      if (
        longTermGoal.text === null &&
        longTermGoal.period === null &&
        longTermGoal.subItems.items.every((item) => isEmptyShortTermGoal(item))
      ) {
        return true;
      }
      return false;
    },
    [],
  );

  const isEmptyShortTermGoal = useCallback(
    (shortTermGoal: ShortTermGoal): Boolean => {
      if (
        shortTermGoal.text === null &&
        shortTermGoal.period === null &&
        shortTermGoal.subItems.items.every((item) =>
          isEmptySupportService(item),
        )
      ) {
        return true;
      }
      return false;
    },
    [],
  );

  const isEmptySupportService = useCallback(
    (supportService: SupportService): Boolean => {
      if (
        supportService.text === null &&
        supportService.subItems.items.every((item) =>
          isEmptySupportServiceType(item),
        )
      ) {
        return true;
      }
      return false;
    },
    [],
  );

  const isEmptySupportServiceType = useCallback(
    (supportServiceType: SupportServiceType): Boolean => {
      if (
        supportServiceType.text === null &&
        supportServiceType.code === null &&
        supportServiceType.insuranceBenefit === null &&
        supportServiceType.establishment === null &&
        supportServiceType.frequency === null &&
        supportServiceType.period === null
      ) {
        return true;
      }
      return false;
    },
    [],
  );

  const deleteNeed = useCallback(
    async (need: Need): Promise<CarePlanTableTwo | null> => {
      try {
        let response = null;
        const res = await CarePlanTableTwoApi.removeNeed(projectId, need.id);
        if (res instanceof EditedCaraPlanTableTwo) {
          response = res.carePlanTableTwo;
        }
        return response;
      } catch (error) {
        throw error;
      }
    },
    [projectId],
  );

  const registerTableTwo = useCallback(
    async (tableTwo: ChatGptNeed[]) => {
      if (!tableTwo) return;
      try {
        const res = await CarePlanTableTwoApi.registerTableTwo(
          projectId,
          tableTwo,
        );
        if (res instanceof RegisterTableTwo) {
          let responseCarePlanTableTwo = res.carePlanTableTwo;
          // 既存の最後のニーズが空欄の場合、削除
          if (
            carePlanTableTwo?.needs.items.slice(-1)[0] &&
            isEmptyNeed(carePlanTableTwo?.needs.items.slice(-1)[0])
          ) {
            const res = await deleteNeed(
              carePlanTableTwo?.needs.items.slice(-1)[0],
            );
            if (res) responseCarePlanTableTwo = res;
          }
          // 前回のたたき台を削除
          if (prevNeeds) {
            for (const need of prevNeeds) {
              const res = await deleteNeed(need);
              if (res) responseCarePlanTableTwo = res;
            }
          }
          setCarePlanTableTwo(responseCarePlanTableTwo);
          setPrevNeeds(res.addedNeeds.items);
        }
      } catch (error) {
        throw error;
      }
    },
    [projectId, carePlanTableTwo, prevNeeds],
  );

  const chooseValue = (): 'summary' | 'fileAndText' => {
    if (summary.length > 1 && (descriptionEnable || !fileDisabled)) {
      // 両方入力されている場合、セレクトボックスで入力した値
      return select;
    } else if (summary.length > 1) {
      return 'summary';
    } else {
      return 'fileAndText';
    }
  };

  const fetchChatGpt = useCallback(async () => {
    if (disabled) {
      return;
    }
    const value = chooseValue();
    let result = null;
    if (value === 'summary') {
      // ミルモレコーダーから連携された要約で生成AIを利用する
      result = ChatGptApi.createByText(summary, careRecipient, projectId);
    } else {
      // ファイルと記述内容で生成AIを利用する
      let bData = fileDataStrings;
      if (files.length > 0 && fileDataStrings.length === 0) {
        // ファイル指定だが、設定されていない場合
        const texts = await Promise.all(files.map((file) => blobToText(file)));
        setFileDataStrings(texts);
        bData = texts;
      } else if (files.length === 0) {
        bData = [];
      }
      result = ChatGptApi.create(
        prompt,
        userConsultation,
        familyConsultation,
        bData,
        files,
        careRecipient,
        projectId,
      );
    }

    result
      .then(async (res) => {
        if (res instanceof Create) {
          if (res.result === 'OK') {
            await registerTableTwo(res.tableTwo);
            onRequestCloseDialog();
            return;
          } else if (res.result === 'RETRY') {
            setRetry(true);
            setFiles([]);
          } else {
            setError(true);
            setFiles([]);
          }
          // カウント再読み込み。
          setSumCount();
        } else {
          setError(true);
          setFiles([]);
        }
        setIsLoading(false);
        return;
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
        setPrevNeeds(undefined);
        setFiles([]);
        return;
      });
  }, [
    prompt,
    userConsultation,
    familyConsultation,
    files,
    fileDataStrings,
    summary,
    select,
  ]);

  const handleClick = useCallback(() => {
    setError(false);
    setRetry(false);
    setIsLoading(true);
    fetchChatGpt();
  }, [fetchChatGpt]);

  const onPromptUpdate = useCallback((value: string | null) => {
    if (!value) return setPrompt('');
    setPrompt(value);
  }, []);
  const onUserConsultationUpdate = useCallback((value: string | null) => {
    if (!value) {
      setUserConsultation('');
      return;
    }
    setUserConsultation(value);
  }, []);
  const onFamilyConsultationUpdate = useCallback((value: string | null) => {
    if (!value) {
      setFamilyConsultation('');
      return;
    }
    setFamilyConsultation(value);
  }, []);

  const hideUserMenu = (): void => {
    setMenuVisible(false);
  };
  const toggle = (): void => {
    setMenuVisible(!menuVisible);
  };

  const blobToText = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject(new Error('Blobの読み込みに失敗しました。'));
      };
      reader.readAsDataURL(blob);
    });
  };

  const setValuesFromPreviousPrompt = (): void => {
    ChatGptApi.getLastPromptHistory().then((res) => {
      if (res instanceof PromptHistory) {
        if (
          res.content.length !== 0 ||
          res.userConsultation.length !== 0 ||
          res.familyConsultation.length !== 0
        ) {
          // 前回の履歴に何かあれば設定。
          // 何もない時は設定しない（入力途中があれば上書きしない為）
          setPrompt(res.content);
          setUserConsultation(res.userConsultation);
          setFamilyConsultation(res.familyConsultation);

          setMenuVisible(false);
        }
      }
    });
  };
  const setValuesFromAssessment = (): void => {
    // 利用者
    setUserConsultation(
      assessmentSheet.content.faceSheet.sodanNaiyo.honnin ?? '',
    );
    // 家族等
    setFamilyConsultation(
      assessmentSheet.content.faceSheet.sodanNaiyo.kaigoShaKazoku ?? '',
    );
    // 現在の状況：各 特記事項を編集し設定
    setPrompt(getPromptText(assessmentSheet));
  };

  const user = useSelector((state: State) => state.authentication.user);
  const isAdmin = user?.isAdmin;

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingContainer} onClick={onClickContainer}>
          <div className={styles.waitingContainer}>
            <Loading />
          </div>
        </div>
      ) : (
        <div className={styles.loadingContainer} onClick={onClickContainer}>
          <div className={styles.container}>
            <div className={styles.closeArea}>
              <button
                className={styles.closeButton}
                onClick={onRequestCloseDialog}
              >
                <CloseIconChatGPT />
              </button>
            </div>
            <div className={styles.headerArea}>
              <div className={styles.titleArea}>
                <div className={styles.contentTitle}>
                  <p>生成AIを利用して課題と目標を入力</p>
                </div>
                <div className={styles.contentSubTitle}>
                  <p>
                    添付ファイルと記述内容から、生成AIを利用して課題と目標を入力します。
                  </p>
                  <p>ファイルと記述内容のどちらか一方だけでも利用可能です。</p>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              {/* 見出し */}
              <div className={styles.label}>
                <div className={styles.infoArea}>
                  <label>連携</label>
                </div>
              </div>
              {/* 項目 */}
              <div className={styles.integration}>
                <div className={styles.integrationButton}>
                  <PrimaryButton
                    onClick={() => {
                      window.open(`${milmoRecorderOrigin}`);
                    }}
                  >
                    ミルモレコーダーとの連携
                  </PrimaryButton>
                </div>
                <ChatGptTextarea
                  initialText={summary}
                  onUpdate={(text) => setSummary(text ?? '')}
                  activated
                />
              </div>
            </div>
            {/* 添付ファイルエリア */}
            <div className={styles.row}>
              {/* 見出し */}
              <div className={styles.label}>
                <div className={styles.infoArea}>
                  <label>添付ファイル</label>
                </div>
              </div>
              {/* 項目 */}
              <div className={styles.field}>
                <div className={styles.fileArea}>
                  <FileUploadArea title={'PDFまたは画像'} setFiles={setFiles} />
                </div>
              </div>
            </div>

            {/* 記述内容エリア */}
            <div className={styles.row}>
              <div className={styles.label}>
                <div className={styles.infoArea}>記述内容</div>
                <span className={styles.importButton}>
                  {assessmentSheet && (
                    <SecondaryButton onClick={setValuesFromAssessment}>
                      アセスメントから取込
                    </SecondaryButton>
                  )}
                </span>
              </div>

              {/* 項目 */}
              <div className={styles.field}>
                <div className={styles.consultationArea}>
                  <div className={styles.callHeading}>
                    <OutsideClickHandler onOutsideClick={hideUserMenu}>
                      <button
                        className={styles.menuDisplayName}
                        onClick={toggle}
                      >
                        <span>呼出</span>
                        <DownArrowIcon />
                      </button>
                      {menuVisible && (
                        <div className={styles.dropDownMenu}>
                          <div
                            className={styles.settings}
                            onClick={setValuesFromPreviousPrompt}
                          >
                            前回生成AIに送った記述
                          </div>
                        </div>
                      )}
                    </OutsideClickHandler>
                  </div>

                  <div className={styles.heading}>
                    相談内容【主訴/本人・家族の希望・困っていることや不安、思い】
                  </div>

                  <div className={styles.consultationSubArea}>
                    <div className={styles.titleArea}>利用者</div>
                    <div className={styles.textArea}>
                      <ChatGptTextarea
                        initialText={userConsultation}
                        onUpdate={onUserConsultationUpdate}
                        activated={true}
                        placeholder={placeholderUserConsultation}
                        maxLength={500}
                      />
                    </div>
                  </div>
                  <div className={styles.consultationSubArea}>
                    <div className={styles.titleArea}>家族等</div>
                    <div className={styles.textArea}>
                      <ChatGptTextarea
                        initialText={familyConsultation}
                        onUpdate={onFamilyConsultationUpdate}
                        activated={true}
                        placeholder={placeholderFamilyConsultation}
                        maxLength={500}
                      />
                    </div>
                  </div>
                  <div className={styles.assignmentHeading}>
                    現在の状況や課題（自立した日常生活の阻害要因）
                  </div>

                  <div className={styles.consultationContentSubArea}>
                    {/* <div className={styles.heading}>
                                            現在の状況や課題（自立した日常生活の阻害要因）
                                        </div> */}
                    <div className={styles.titleArea}></div>
                    <div className={styles.textArea}>
                      <ChatGptTextarea
                        initialText={prompt}
                        onUpdate={onPromptUpdate}
                        activated={true}
                        placeholder={placeholderContent}
                        maxLength={3000}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {error && (
              <p className={styles.error}>生成AIでの作成に失敗しました。</p>
            )}
            {retry && (
              <>
                <p className={styles.error}>
                  適切な回答が得られませんでした。再度プロンプトの送信をお願いします。
                </p>
              </>
            )}

            {renderUsableNumber(count, limitCount)}
            {bothAreEntered && (
              <div className={styles.selectValueArea}>
                <span className={styles.description}>
                  ミルモレコーダーからの連携結果と、入力欄の両方が設定されています。使用する方を選択し、「生成AIに送る」を押してください
                </span>
                <div className={styles.selectButtonArea}>
                  <label
                    className={`${styles.radio} ${select === 'summary' ? styles.selected : ''}`}
                  >
                    <input
                      type="radio"
                      value="summary"
                      onChange={() => setSelect('summary')}
                      checked={'summary' === select}
                    />
                    <span>ミルモレコーダーから連携された要約を使用する</span>
                  </label>
                  <label
                    className={`${styles.radio} ${select === 'fileAndText' ? styles.selected : ''}`}
                  >
                    <input
                      type="radio"
                      value="filesAndText"
                      onChange={() => setSelect('fileAndText')}
                      checked={'fileAndText' === select}
                    />
                    <span>添付ファイル/記述内容を使用する</span>
                  </label>
                </div>
              </div>
            )}
            <div className={styles.button}>
              <PrimaryButton
                onClick={handleClick}
                disabled={disabled}
                width={256}
              >
                生成AIに送る
              </PrimaryButton>
            </div>
            <div className={styles.footerArea}>
              {
                '※利用可能回数の追加をご希望の方は、お電話 03-6205-7308（受付時間 平日10:00〜18:00）、または'
              }
              <a
                href={
                  'https://docs.google.com/forms/d/e/1FAIpQLSdWsMa2FJq3PBko3IiujxqBUrHnUsdyefnnN7bSy2jovfAiMQ/viewform'
                }
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.link}
              >
                {'お問い合わせ'}
              </a>
              {'よりご連絡ください。'}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

type Props = {
  projectId: string;
  carePlanTableTwo: CarePlanTableTwo | undefined;
  setCarePlanTableTwo: (
    value: React.SetStateAction<CarePlanTableTwo | undefined>,
  ) => void;
  prevNeeds: Need[] | undefined;
  setPrevNeeds: React.Dispatch<React.SetStateAction<Need[] | undefined>>;
  onRequestCloseDialog: () => void;
  usageInfo?: ChatgptManageUsage | undefined;
  careRecipient: SimpleCareRecipient | null;
};

function getTextLimitRatio(
  usageInfo: ChatgptManageUsage,
  count: number,
): number {
  const ratio: number = (count / usageInfo.sendLimit) * 100;
  return ratio;
}

function getWarningLevel(ratio: number): AISendWarningLevel {
  // 警告レベル判定
  // 注意、警告
  // 2:警告 : 90% 超えたら
  // 1:注意 : 80% 超えたら
  // 0:通常 : 79% 以下
  let level = AISendWarningLevel.NORMAL;
  if (ratio >= 100) {
    level = AISendWarningLevel.ERROR;
  } else if (ratio > 90) {
    level = AISendWarningLevel.WARNING;
  } else if (ratio > 80) {
    level = AISendWarningLevel.CAUTION;
  }
  return level;
}

function renderUsableNumber(
  count: number | undefined,
  limitCount: number | undefined,
): ReactNode {
  if (count !== undefined && limitCount !== undefined) {
    const showCount = limitCount - count < 0 ? 0 : limitCount - count;
    return (
      <>
        <div className={styles.usableNumber}>
          <p data-testid="test">利用可能回数：{showCount}</p>
        </div>
      </>
    );
  }
  return <></>;
}

function getTextTokkiContent(
  label: string,
  content: any,
  baseMsg: string,
): string {
  if (baseMsg.length > 250) {
    // すでに 250文字 を超えているなら新しい文字列は追加しない。
    return '';
  }
  let contentText = '';
  if (content != null) {
    const isCrlf = baseMsg.length > 0;
    // 末尾の 改行を削除
    const tmpContent = content.replace(/r?\n$/, '');
    let tmpLavel = label;
    if (isCrlf) {
      tmpLavel = '\n' + label;
    }
    contentText = tmpLavel + '：' + tmpContent;

    if (baseMsg.length + contentText.length > 250) {
      // 新しい文字列を加算して 250文字 を超えていても戻さない。
      return '';
    }
  }
  return contentText;
}

function getPromptText(assessmentSheet: AssessmentSheet): string {
  let promptText = '';

  // 健康
  promptText += getTextTokkiContent(
    '健康',
    assessmentSheet.content.honninNoKenkoJotaiJushinNadoNoJokyo
      .tokkiSeikatsuJoHairyoSuBekiKadaiNado,
    promptText,
  );
  // 動作
  promptText += getTextTokkiContent(
    '動作',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .kihonDosa.taiiHenkanKikyo.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 入浴
  promptText += getTextTokkiContent(
    '入浴',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .kihonDosa.nyuyoku.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 意思疎通
  promptText += getTextTokkiContent(
    '意思疎通',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .kihonDosa.communicationNoJokyoHoho.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 食事
  promptText += getTextTokkiContent(
    '食事',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .seikatsuKino.shokuji.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 排泄
  promptText += getTextTokkiContent(
    '排泄',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .seikatsuKino.haisetsuNado.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 外出
  promptText += getTextTokkiContent(
    '外出',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .seikatsuKino.gaishutsu.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 認知
  promptText += getTextTokkiContent(
    '認知',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .ninchiKinoSeishinKodoshogaiZempan.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 社会
  promptText += getTextTokkiContent(
    '社会',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .shakaiSeikatsuRyoku.tokkiKaiketsuSuBekiKadaiNado,
    promptText,
  );
  // 医療
  promptText += getTextTokkiContent(
    '医療',
    assessmentSheet.content.honninNoKihonDosaNadoNoJokyoToEnjoNaiyoNoShosai
      .iryoKenkoKankei.tokkiSeikatsuJoHairyoSuBekiKadaiNado,
    promptText,
  );
  return promptText;
}
